import React from "react"
import { ProjectLayout } from "../../components/common/ProjectsLayout"
import { useStaticQuery, graphql } from "gatsby"
import { PROJECT_LUXO_SEO } from "../../config/siteMetaData"

const LuxoPage = () => {
  const data = useStaticQuery(graphql`
    query {
      luxoCollabProject: file(relativePath: { eq: "share.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ProjectLayout
      image={data.luxoCollabProject.childImageSharp.fluid}
      header={
        <a
          href="https://luxomusic.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          luxomusic.com
        </a>
      }
      intro={
        <>
          Daniel “Luxo” Nish is an up-and-coming music producer based in Los
          Angeles, CA and the co-founder of the House music label Rock Bottom
          Records{" "}
          <a
            href="https://www.rockbottomrecs.com"
            rel="noreferrer noopener"
            target="_blank"
            style={{ color: "rgb(73, 133, 237)", textDecoration: "underline" }}
          >
            www.rockbottomrecs.com
          </a>
          . Progressively making bigger waves in influential House music circles
          such as Dirtybird, Hood Politics, and more, and performing at popular
          club venues like LA’s Exchange, Luxo and his cadre are on a path to
          success.
        </>
      }
      body={
        <>
          <a
            href="https://github.com/nicknish"
            rel="noreferrer noopener"
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            @Nick Nish
          </a>{" "}
          collaborated to bring Luxo's youthful and vibrant mixing style onto
          the web. This project was my introduction to Gatsby.js, PostCSS and
          CSS modules. While Nick handled the Landing page, I was responsible
          for the{" "}
          <a
            href="https://https://www.luxomusic.com/tutoring"
            rel="noreferrer noopener"
            target="_blank"
            style={{
              textDecoration: "underline",
              color: "rgb(73, 133, 237)",
            }}
          >
            Feedback & Mentoring page
          </a>
          .
        </>
      }
      conclusion="The project was expediant in nature. Luxo wanted a site for himself to 
      promote the record label and well as connect with his fans in the event they felt 
      like learning from him. Despite the short-term project, I was able to learn so much; 
      new tech and css methodology. My biggest take-away was being able to collaborate with 
      a Senior developer, it tested to see whether I had enough skill to 'keep up' and make an impact 
      on a project.
      "
      tech="React.js, Gatbsy.js, PostCSS, CSS modules, Tailwind.css, Netlify, Zeplin"
      {...PROJECT_LUXO_SEO}
    />
  )
}

export default LuxoPage
