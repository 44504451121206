import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import style from "./projectsLayout.module.css"
import Layout from "../../layout"
import { PROJECTS } from "../../../utils/urls"
export const ProjectHeaderImage = ({ image, imgProps }) => {
  return (
    <Img
      className={style.projectPageImage}
      fluid={image}
      objectFit="cover"
      objectPosition="center center"
      {...imgProps}
    />
  )
}

export const ProjectPageHeader = ({ children }) => {
  return <h2 className={style.projectPageHeader}>{children}</h2>
}

export const ProjectPageIntro = ({ children }) => {
  return <p className={style.projectPageDescription}>{children}</p>
}

export const ProjectPageBody = ({ children }) => {
  return <p className={style.projectPageDescription}>{children}</p>
}

export const ProjectPageConclusion = ({ children }) => {
  return <p className={style.projectPageDescription}>{children}</p>
}

export const ProjectPageTech = ({ children }) => {
  return <i className={style.projectPageTech}>Tech: {children}</i>
}

export const ProjectLayout = ({
  image,

  header,
  intro,
  body,
  conclusion,
  tech,
  ...seoProps
}) => {
  return (
    <Layout {...seoProps}>
      <div className={style.returnDiv}>
        <Link to={PROJECTS} className={style.returnBttn}>
          back to projects
        </Link>
      </div>
      <ProjectHeaderImage image={image} />
      <div className={style.projectPageContainer}>
        <ProjectPageHeader>{header}</ProjectPageHeader>
        <ProjectPageIntro>{intro}</ProjectPageIntro>
        <ProjectPageBody>{body}</ProjectPageBody>
        <ProjectPageConclusion>{conclusion}</ProjectPageConclusion>
        <ProjectPageTech>{tech}</ProjectPageTech>
      </div>
    </Layout>
  )
}
